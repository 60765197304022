export const state = () => ({
  isKycNeeded: false
})

export const mutations = {
  set_data(state, data) {
    state.isKycNeeded = data
  }
}

export const actions = {
  getKyc({ commit }, data) {
    try {
      commit('set_data', data)
      this.$auth.$storage.setUniversal('kycStatus', data)
    } catch (error) {}
  }
}
