export const state = () => ({
  matchIds: [],
  bettingType: '',
  market: [],
  runner: [],
  allRunners: [],
  placeBetJSON: {},
  odds: 0,
  runs: 0,
  position: 0,
  oddsIndex: 0,
  type: '',
  loading: false,
  error: {},
  success: '',
  warning: '',
  marker3etId: null,
  urlMap: {
    default: 'Betfair/placePlayerBetNew',
    threeEt: 'Betfair/placePlayerBet3ET',
    fancy: 'Betfair/placePlayerBetFancyCustom',
    bookmaker: 'Betfair/placePlayerBetNewBookMaker',
    lottery: 'Betfair/placePlayerBetNewBookMaker',
    multiHorse: 'Betfair/placePlayerBetNewforMultiHorse',
    lineLadder: 'PlaceBetLineSession/PlaceLineSessionBetForPlayer'
  }
})

export const mutations = {
  set_matchIds(state, data) {
    state.matchIds = data
  },
  set_placeBetJSON(
    state,
    {
      market,
      runner,
      runs,
      odds,
      position,
      stake,
      type,
      winning,
      ip,
      accessToken,
      userName
    }
  ) {
    let output
    if (state.bettingType === 'default') {
      output = {
        betType: 'odds',
        clientIP: ip,
        accessToken,
        _accessToken: accessToken,
        userName,
        odds,
        position,
        stake,
        type: type.toUpperCase(),
        updatedodds: winning / 100,
        eventId: market.parentCategory.betfairId,
        event: market.eventName,
        book: market.runners,
        marketId: market.betfairId,
        selectionId: runner.betfairId,
        selectionName: runner.name,
        marketName: market.name,
        handicap: runner.handicap,
        error: false
      }
      // console.log(output)
    } else if (state.bettingType === 'threeEt') {
      output = {
        betType: 'odds',
        clientIP: ip,
        accessToken,
        _accessToken: accessToken,
        userName,
        odds,
        position,
        stake,
        type: type.toUpperCase(),
        updatedodds: winning / 100,
        eventId: market.parentCategory.betfairId,
        event: market.eventName,
        book: market.runners,
        marketId: market.betfairId,
        selectionId: runner.betfairId,
        selectionName: runner.name,
        marketName: market.name,
        handicap: runner.handicap,
        error: false
      }
    } else if (state.bettingType === 'fancy') {
      output = {
        runs,
        odds,
        type: type.toUpperCase(),
        userName,
        stake,
        event: runner.eventName,
        selectionId: runner.betfairId,
        selectionName: runner.name,
        marketId: runner.betfairId,
        accessToken,
        _accessToken: accessToken,
        updatedodds: odds - 1,
        custom: true,
        betType: 'fancy-market-custom',
        error: false,
        clientIP: ip
      }
    } else if (
      state.bettingType === 'bookmaker' ||
      state.bettingType === 'lottery' ||
      state.bettingType === 'multiHorse'
    ) {
      output = {
        betType: 'odds',
        clientIP: ip,
        accessToken,
        _accessToken: accessToken,
        userName,
        odds,
        position,
        refOdds: odds,
        stake,
        type: type.toUpperCase(),
        updatedodds: winning / 100,
        eventId: market.parentCategory.betfairId,
        event: market.eventName,
        book: market.runners,
        marketId: market.betfairId,
        selectionId: runner.betfairId,
        selectionName: runner.name,
        marketName: market.name,
        handicap: runner.handicap,
        error: false,
        blank: '',
        placeBetCalcType:
          market.placeBetCalcType || market.placeBetCalcType === 0
            ? market.placeBetCalcType
            : 1,
        isJodiMarket: market.isJodiMarket ? market.isJodiMarket : false
      }
    } else if (state.bettingType === 'lineLadder') {
      output = {
        _accessToken: accessToken,
        betType: state.bettingType,
        custom: true,
        error: false,
        marketId: market.betfairId,
        runs: odds,
        odds: 2,
        selectionId: runner.betfairId,
        selectionName: runner.name,
        stake,
        type: type.toUpperCase(),
        updatedodds: winning / 100,
        userName
      }
    }

    if (this.type === 'lay') {
      output.liability = winning
    } else {
      output.profit = winning
    }
    state.placeBetJSON = output
  },
  openPopover(
    state,
    {
      market,
      runner,
      allRunners,
      odds,
      oddsIndex,
      type,
      bettingType,
      runs,
      threeETId
    }
  ) {
    // console.log({ market, runner, allRunners, odds, type, bettingType, runs })
    if (odds !== undefined) {
      state.market = market
      state.runner = runner
      state.allRunners = allRunners
      state.odds = odds
      state.oddsIndex = oddsIndex
      state.runs = runs
      state.type = type
      state.bettingType = bettingType
      if (threeETId) {
        state.threeETId = threeETId
      }
    } else {
      let error
      if (odds !== undefined) {
        error = 'Odds are not there'
      }
      state.error = error
      this.commit('snackbar/open', {
        text: state.error,
        color: 'error'
      })
    }
  },
  set_error(state, data) {
    let error
    if (data.error === 'MIN_BET_STAKE_REQUIRED') {
      error = 'Please increase bet amount'
    } else if (data.error === 'accessDenied') {
      error = 'Access Denied'
    } else if (data.error === 'no match found 1') {
      error = 'Market is suspended'
    } else if (
      data.error === 'No match found' ||
      data.error === 'no match found 1' ||
      data.error === 'no match found 2' ||
      data.error === 'no match found 3' ||
      data.error === 'no match found 4'
    ) {
      error = 'Market is Closed'
    } else if (data && data.error === 'Insufficient credit limit') {
      error = 'Insufficient credit limit'
    } else if (data && data.error === 'Exceeded the profit limit') {
      error = 'Profit Limit Exceeded'
    } else if (data && data.error === 'Exceeded the Max Market limit') {
      error = 'Max win limit exceeded'
    } else if (data && data.status === 'UNMATCHED') {
      error = data.message
    } else if (data && data.error === 'Previous Bet In Process') {
      error = data.error
    } else if (data && data.error && data.error.message === 'limitExceeded') {
      error = 'Max Bet Limit Reached'
    } else if (
      data &&
      data.error &&
      data.error.message === 'Your account has been suspended.'
    ) {
      error = 'Your account has been suspended'
    } else if (data && data.error && data.error.message === 'Betting locked ') {
      error = 'Betting locked Please contact your upline'
    } else if (data && data.message === 'timeout of 600100ms exceeded') {
      error = 'Error Placing Bet. Please Try again.'
    } else if (data && data.message === 'Network Error') {
      error =
        'Unable to place bet due to bad network. Please refresh your page for better experience.'
    } else if (
      data &&
      data.error &&
      data.error.body === 'Unable to place bet as rate is greater than 3'
    ) {
      error = 'Unable to place bet as rate is greater than 3'
    } else if (data && data.error && data.error === 'no horse found') {
      error = 'Selected horse is suspended'
    } else if (
      data &&
      data.error &&
      data.error.body &&
      data.error.body === 'Odds have changed.'
    ) {
      error = 'Odds have changed.'
    } else if (data && data.error === 'Odds Lay has changed') {
      error = 'Odds Lay has changed'
    } else if (data && data.error === 'Odds Back has changed') {
      error = 'Odds Back has changed'
    } else if (
      data &&
      (data.error === 'Market Suspended' ||
        data.error === 'Market is Suspended')
    ) {
      error = 'Market Suspended'
    } else if (data && data.error === 'Ball Running') {
      error = 'Ball Running'
    } else if (data && data.error && data.error.status === 500) {
      error = 'Market is Suspended'
    } else if (
      data &&
      data.error &&
      data.error.error &&
      data.error.error.message
    ) {
      error = data.error.error.message
    } else if (
      data &&
      data.error &&
      data.error.body &&
      data.error.body.message
    ) {
      error = data.error.body.message
    } else if (data && data.error && data.error.message) {
      error = data.error.message
    } else if (data && data.error && data.error.body) {
      error = data.error.body
    } else if (data && data.status === 'PARTIALLY_MATCHED') {
      error = data.message
    } else if (data && data.message) {
      error = data.message
    } else {
      error = 'Error while placing Bet'
    }
    state.error = error
  },
  reset_error(state) {
    state.error = ''
  },
  reset_success(state) {
    state.success = ''
  },
  reset_warning(state) {
    state.warning = ''
  },
  set_success(state, data) {
    state.success = data
  },
  set_warning(state, data) {
    state.warning = data
  },
  set_blank(state, data) {
    state.blank = data
  },

  set_loading(state, data) {
    state.loading = data
  },
  reset_popover(state) {
    state.market = []
    state.runner = []
    state.allRunners = []
    state.odds = 0
    state.oddsIndex = 0
    state.runs = 0
    state.type = ''
    state.bettingType = ''
  }
}

export const actions = {
  async execute({ commit, state }, data) {
    let accessToken, userName, dataPlaceBet
    if (this.$auth.loggedIn) {
      accessToken = this.$auth.getToken('customLocal')
      userName = this.$auth.user.username
    }
    let baseurl = process.env.SPORTSBOOK_BET_API_URL
    commit('set_placeBetJSON', { ...data, accessToken, userName })
    dataPlaceBet = {
      _accessToken: accessToken,
      body: [state.placeBetJSON]
    }
    if (state.bettingType === 'lineLadder') {
      baseurl = process.env.LINE_SESSSION_URL
      dataPlaceBet = state.placeBetJSON
    }
    try {
      commit('set_loading', true)
      commit('reset_error')
      commit('reset_success')
      commit('reset_warning')
      const response = await this.$axios({
        method: 'post',
        baseURL: baseurl,
        url: state.urlMap[state.bettingType],
        timeout: 600100,
        data: dataPlaceBet
      })
      // console.log('Placebet Response', response.data)
      if (!response.data.value) {
        commit('set_error', response.data)
      }
      if (response.data.value) {
        if (state.bettingType !== 'lineLadder') {
          if (
            response.data &&
            response.data.data &&
            response.data.data[0] &&
            response.data.data[0].status === 'EXECUTION_COMPLETE'
          ) {
            if (
              response.data.message &&
              response.data.message.status === 'MATCHED'
            ) {
              commit('set_success', response.data.message)
            } else if (
              response.data.message &&
              response.data.message.status === 'PARTIALLY_MATCHED'
            ) {
              commit('set_warning', response.data.message)
            } else if (
              response.data.message &&
              response.data.message.status === 'UNMATCHED' &&
              response.data.data[0].betType !== 'threeEt'
            ) {
              commit('set_success', response.data.message)
            } else if (
              response.data.message &&
              response.data.message.status === 'UNMATCHED' &&
              response.data.data[0].betType === 'threeEt'
            ) {
              commit('set_blank', true)
            } else {
              commit('set_success', 'Bet Placed successfully!')
            }
          } else if (
            response.data &&
            response.data.data &&
            response.data.data[0] &&
            response.data.data[0].status === 'EXECUTABLE'
          ) {
            if (
              response.data.message &&
              response.data.message.status === 'UNMATCHED' &&
              response.data.data[0].betType !== 'threeEt'
            ) {
              commit('set_success', response.data.message)
            } else if (
              response.data.message &&
              response.data.message.status === 'PARTIALLY_MATCHED'
            ) {
              commit('set_warning', response.data.message)
            } else if (
              response.data.message &&
              response.data.message.status !== 'UNMATCHED'
            ) {
              commit('set_success', response.data.message)
            } else if (
              response.data.message &&
              response.data.message.status === 'UNMATCHED' &&
              response.data.data[0].betType === 'threeEt'
            ) {
              commit('set_blank', true)
            } else {
              commit('set_success', 'Bet Unmatched!')
            }
          } else if (
            response.data &&
            response.data.message &&
            response.data.message.status === 'MATCHED'
          ) {
            commit('set_success', response.data.message)
          } else {
            commit('set_loading', false)
          }
        } else if (state.bettingType === 'lineLadder') {
          if (
            response.data &&
            response.data.data &&
            response.data.data.isSuccess &&
            response.data.data.status !== 'UNMATCHED'
          ) {
            commit('set_success', response.data.data.message)
          } else if (
            response.data &&
            response.data.data &&
            response.data.data.isSuccess &&
            response.data.data.status === 'UNMATCHED'
          ) {
            commit('set_warning', response.data.data)
          } else if (
            response.data &&
            response.data.data &&
            !response.data.data.isSuccess
          ) {
            commit('set_error', response.data.data)
          } else {
            commit('set_loading', false)
          }
        }
      }

      commit('set_loading', false)
    } catch (error) {
      // console.log(error)
      if (
        error &&
        error.response &&
        error.response.data === 'upstream request timeout'
      ) {
        const data = {}
        data.message = 'Error while placing bet'
        commit('set_error', data)
      } else {
        commit('set_error', error)
        commit('set_loading', false)
      }
      commit('set_loading', false)
    }
  }
}
