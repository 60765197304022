<template>
  <v-app>
    <play-cache-clear-modal></play-cache-clear-modal>
    <notification-marquee></notification-marquee>
    <!-- <notification-marquee2></notification-marquee2> -->
    <v-main class="pt-0">
      <snackbar />
      <v-lazy class="fill-height">
        <transition name="slide-fade" :duration="550">
          <nuxt />
        </transition>
      </v-lazy>
    </v-main>
  </v-app>
</template>

<script>
import constants from '../const'
// import eventScript from '@/services/script'
import Snackbar from '@/components/layouts/Snackbar'
import NotificationMarquee from '@/components/NotificationMarquee'
import PlayCacheClearModal from '@/components/CacheClearModal'

// import NotificationMarquee2 from '@/components/NotificationMarquee2'
// import loader from '@/assets/logos/PlayExch-logo.webp'

export default {
  components: {
    NotificationMarquee,
    PlayCacheClearModal,
    // NotificationMarquee2,
    Snackbar
  },
  data() {
    return {
      playCacheModal: constants.playCacheModal
    }
  },
  async created() {
    // eventScript.clarity()
    try {
      if (!document.getElementById('OneSignalSDK')) {
        // One Signal start Here
        const oneSignal = document.createElement('script')
        oneSignal.setAttribute('id', 'OneSignalSDK')
        oneSignal.setAttribute(
          'src',
          'https://cdn.onesignal.com/sdks/OneSignalSDK.js'
        )
        await document.head.appendChild(oneSignal)
        if (process.env && process.env.APP_TITLE) {
          let apiKey = process.env.OS_CODE
          if (window.location.hostname === 'matrixexch9.co') {
            apiKey = process.env.MATRIX_OS_CODE_CO
          } else if (window.location.hostname === 'matrixexch9.com') {
            apiKey = process.env.MATRIX_OS_CODE_COM
          } else if (window.location.hostname === 'matrixexch9.org') {
            apiKey = process.env.MATRIX_OS_CODE_ORG
          } else if (window.location.hostname === 'matrixexch9.io') {
            apiKey = process.env.MATRIX_OS_CODE_IO
          } else if (window.location.hostname === '77exch.club') {
            apiKey = process.env.EXCH_OS_CODE_CLUB
          } else if (window.location.hostname === '77exch.in') {
            apiKey = process.env.EXCH_OS_CODE_IN
          } else if (window.location.hostname === '77exch.com') {
            apiKey = process.env.EXCH_OS_CODE_COM
          } else if (window.location.hostname === 'winner7.club') {
            apiKey = process.env.WINNER_OS_CODE_CLUB
          } else if (window.location.hostname === 'winner7.co') {
            apiKey = process.env.WINNER_OS_CODE_CO
          } else if (window.location.hostname === 'winner7.in') {
            apiKey = process.env.WINNER_OS_CODE_IN
          } else if (window.location.hostname === 'winner7.io') {
            apiKey = process.env.WINNER_OS_CODE_IO
          } else if (window.location.hostname === 'winner7.org') {
            apiKey = process.env.WINNER_OS_CODE_ORG
          } else if (window.location.hostname === 'matrixexch.com') {
            apiKey = process.env.MATRIX_OS_CODE_COM
          } else if (window.location.hostname === 'matrixexch.club') {
            apiKey = process.env.MATRIX_OS_CODE_CLUB
          }
          window.OneSignal = window.OneSignal || []
          OneSignal.push(function () {
            OneSignal.init({
              appId: apiKey
            })
          })
        }
        // One Signal End Here
      }
    } catch (error) {}
  }
}
</script>
