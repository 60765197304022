export const state = () => ({
  loading: false,
  error: {},
  guestUserData: {}
})

export const mutations = {
  set_guest_user(state, data) {
    state.guestUserData = data
  },
  set_guest_user_data(state, data) {
    const storeData = {
      _accessToken: state.guestUserData,
      _id: data._id,
      username: data.username,
      currencyType: data.currencyType,
      displayName: data.displayName
    }
    localStorage.setItem('guestUserData', JSON.stringify(storeData))
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}
export const actions = {
  async getGuestUser({ commit }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'POST',
        baseURL: process.env.USERS_API_URL,
        url: 'dummyuser/getUsersDetails',
        data: {
          date: 'today'
        }
      })
      if (response?.status === 200 && response?.data) {
        commit('set_guest_user', response.data.data)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getGuestUserData({ commit, state }) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'POST',
        baseURL: process.env.USER_V2_API_URL,
        url: 'member/verifyjwt',
        data: {
          token: state.guestUserData
        }
      })
      if (response?.data?.value) {
        commit('set_guest_user_data', response.data.data)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
