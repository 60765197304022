<template>
  <v-app>
    <!-- <placebet-loader
      v-if="
      $auth.loggedIn &&
      this.$store.state.settings.userConfig &&
      this.$store.state.settings.userConfig.oneClickStatus
      "
      fixed
      ></placebet-loader> -->

    <div v-if="loaded">
      <component :is="playCacheClearModal"></component>
    </div>
    <div v-if="loaded">
      <component :is="shareModal"></component>
    </div>
    <div v-if="loaded">
      <component :is="notificationMarquee"></component>
    </div>
    <!-- {{ mobileDrawer }} -->
    <!-- {{ megaMenuDrawer }} -->
    <div v-if="loaded && $breakpoint.smAndDown">
      <component :is="megaMenuDrawer"></component>
    </div>
    <div v-if="loaded && $breakpoint.smAndDown">
      <component :is="mobileDrawer"></component>
    </div>
    <div v-if="loaded">
      <component :is="networkBar"></component>
    </div>
    <div v-if="loaded && $auth.loggedIn">
      <component :is="mainDrawer"></component>
    </div>
    <div>
      <div
        :class="{
          'casino-wac':
            $route.name === 'casino-casino-list' ||
            $route.name === 'casino-games'
        }"
      ></div>
      <div v-if="loaded">
        <component :is="headerBar"></component>
      </div>
      <!-- <HeaderBar /> -->
      <!-- <div
        v-if="
        loaded &&
        $breakpoint.smAndDown &&
        $route.name === 'premium-sportsbook'
        "
        >
        <component :is="navBar" class="premium-topbar"></component>
      </div> -->
      <!-- <HeaderBar /> -->
      <v-main
        v-if="loaded"
        class="main-section"
        :class="{
          'mt-15': $breakpoint.smAndDown,
          'pb-15':
            $breakpoint.smAndDown &&
            isBottomDrawerOpen &&
            (hasBottomBar === undefined ||
              hasBottomBar == true ||
              hasBottomBar == 'true'),
          'mb-12':
            this.$route.name !== 'live-casino-system-pageNo' &&
            (hasBottomBar === undefined ||
              hasBottomBar == true ||
              hasBottomBar == 'true'),
          'mb-2':
            this.$route.name !== 'live-casino-system-pageNo' &&
            !(
              hasBottomBar === undefined ||
              hasBottomBar == true ||
              hasBottomBar == 'true'
            )
        }"
      >
        <component :is="snackbar"></component>
        <!-- <v-lazy
        :class="{
          '': $breakpoint.smAndDown,
          'mt-1': !$breakpoint.smAndDown
        }"
        >  -->
        <!-- <Transition name="slide-fade" :duration="50"> -->
        <v-lazy>
          <nuxt />
        </v-lazy>
        <!-- </Transition> -->
        <!-- </v-lazy> -->
      </v-main>
      <div
        v-if="
          loaded &&
          $breakpoint.smAndDown &&
          (hasBottomBar === undefined ||
            hasBottomBar == true ||
            hasBottomBar == 'true')
        "
      >
        <component :is="navBar" class="premium-topbar"></component>
      </div>
      <!-- <nav-bar /> -->
    </div>
    <div
      v-if="
        loaded &&
        ($route.name === 'index' ||
          $route.name === 'favorites' ||
          $route.name === 'inplay' ||
          $route.name === 'sportsbook-category' ||
          $route.name === 'sportsbook' ||
          $route.name === 'sportsbook-category-subCategory-id' ||
          $route.name === 'sportsbook-category-subCategory-id-betradar' ||
          $route.name === 'sportsbook-category-id')
      "
      class="oneclick"
    >
      <component :is="oneclickBet"></component>
    </div>
    <div
      v-if="
        loaded &&
        $auth.loggedIn &&
        $breakpoint.smAndDown &&
        $route.name !== 'premium-sportsbook' &&
        $route.name !== 'live-cards' &&
        $route.name !== 'casino-casino-list' &&
        $route.name !== 'casino-games'
      "
    >
      <component :is="favoritesMarquee" id="fav-marque"></component>
    </div>
    <div v-if="loaded && $auth.user && $auth.user._id" class="d-none">
      <component :is="ipQuality"></component>
    </div>
    <!-- <footer-bar /> -->
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
// import { mapState } from 'vuex'
import constants from '../const'
// import PlacebetLoader from '@/components/PlacebetLoader'
// import FooterBar from '@/components/layouts/FooterBar'
import eventScript from '@/services/script'
// import NavBar from '@/components/layouts/Navbar'
// import loader from '@/assets/logos/PlayExch-logo.png'
// import HeaderBar from '@/components/layouts/HeaderBar'

export default {
  name: 'Default',
  middleware: ['maintenanceCheck'],
  components: {
    // HeaderBar
    // LoadingSVG,
    // FooterBar,
    // PlacebetLoader,
  },
  data() {
    return {
      loaded: false,
      headerBar: null,
      playCacheClearModal: null,
      shareModal: null,
      notificationMarquee: null,
      mobileDrawer: null,
      megaMenuDrawer: null,
      navBar: null,
      networkBar: null,
      mainDrawer: null,
      snackbar: null,
      oneclickBet: null,
      favoritesMarquee: null,
      ipQuality: null,
      // loader,
      playCacheModal: constants.playCacheModal,
      hasBottomBar: process.env.BOTTOM_BAR
    }
  },
  computed: {
    ...mapState({
      // apiKey: (state) => state.oneSignalKey.apiKey.data,
      gameConfigData: (state) => state.notificationMarquee.gameConfigData,
      isBottomDrawerOpen: (state) =>
        state.notificationMarquee.isBottomDrawerOpen,
      maintenanceStatus: (state) => state.maintenance.maintenanceStatus
    })
  },
  mounted() {
    this.loadComponent()
    if (sessionStorage.getItem('isVpnDetected')) {
      this.$store.commit('snackbar/open', {
        text:
        sessionStorage.getItem('isVpnDetected') ||
          'Unable to proceed due to use of VPN!!!',
        color: 'error'
      })
      sessionStorage.removeItem('isVpnDetected')
    }
    // await this.oneSignalKey()
  },
  // async created() {
  created() {
    // if (process.client && !localStorage.getItem('guestUserData')) {
    //   await this.$store.dispatch('guestUser/getGuestUser')
    //   await this.$store.dispatch('guestUser/getGuestUserData')
    // }
  },
  methods: {
    async loadComponent() {
      // plugins
      await import('@/plugins/textToIcon.js')
      await import('@/plugins/betslipOpener.js')

      // components
      const { default: HeaderBar } = await import(
        '@/components/layouts/HeaderBar.vue'
      )
      this.headerBar = HeaderBar

      this.loaded = true

      const { default: NavBar } = await import('@/components/layouts/Navbar')

      this.navBar = NavBar
      const { default: MobileDrawer } = await import(
        '@/components/layouts/mobileDrawer'
      )
      this.mobileDrawer = MobileDrawer

      const { default: megaMenuDrawer } = await import(
        '@/components/layouts/megaMenuDrawer'
      )
      this.megaMenuDrawer = megaMenuDrawer
      const { default: NetworkBar } = await import(
        '@/components/layouts/NetworkBar'
      )
      this.networkBar = NetworkBar

      const { default: MainDrawer } = await import(
        '@/components/layouts/Drawer'
      )
      this.mainDrawer = MainDrawer

      const { default: Snackbar } = await import(
        '@/components/layouts/Snackbar'
      )
      this.snackbar = Snackbar

      const { default: OneclickBet } = await import('@/components/OneclickBet')
      this.oneclickBet = OneclickBet

      const { default: FavoritesMarquee } = await import(
        '@/components/favorites/FavoritesMarquee'
      )
      this.favoritesMarquee = FavoritesMarquee

      const { default: IpQuality } = await import('@/components/ipquality')
      this.ipQuality = IpQuality
      const { default: NotificationMarquee } = await import(
        '@/components/NotificationMarquee.vue'
      )
      this.notificationMarquee = NotificationMarquee

      const { default: PlayCacheClearModal } = await import(
        '@/components/CacheClearModal.vue'
      )
      this.playCacheClearModal = PlayCacheClearModal

      const { default: ShareModal } = await import(
        '@/components/ShareModal.vue'
      )
      this.shareModal = ShareModal

      if (process.client) {
        eventScript.playTechFbPixelCode()
        // eventScript.clarity()
        try {
          if (!document.getElementById('OneSignalSDK')) {
            // One Signal start Here
            const oneSignal = document.createElement('script')
            oneSignal.setAttribute('id', 'OneSignalSDK')
            oneSignal.setAttribute(
              'src',
              'https://cdn.onesignal.com/sdks/OneSignalSDK.js'
            )
            await document.head.appendChild(oneSignal)
            if (process.env && process.env.APP_TITLE) {
              let apiKey = process.env.OS_CODE
              if (window.location.hostname === 'matrixexch9.co') {
                apiKey = process.env.MATRIX_OS_CODE_CO
              } else if (window.location.hostname === 'matrixexch9.com') {
                apiKey = process.env.MATRIX_OS_CODE_COM
              } else if (window.location.hostname === 'matrixexch9.org') {
                apiKey = process.env.MATRIX_OS_CODE_ORG
              } else if (window.location.hostname === 'matrixexch9.io') {
                apiKey = process.env.MATRIX_OS_CODE_IO
              } else if (window.location.hostname === '77exch.club') {
                apiKey = process.env.EXCH_OS_CODE_CLUB
              } else if (window.location.hostname === '77exch.in') {
                apiKey = process.env.EXCH_OS_CODE_IN
              } else if (window.location.hostname === '77exch.com') {
                apiKey = process.env.EXCH_OS_CODE_COM
              } else if (window.location.hostname === 'winner7.club') {
                apiKey = process.env.WINNER_OS_CODE_CLUB
              } else if (window.location.hostname === 'winner7.co') {
                apiKey = process.env.WINNER_OS_CODE_CO
              } else if (window.location.hostname === 'winner7.in') {
                apiKey = process.env.WINNER_OS_CODE_IN
              } else if (window.location.hostname === 'winner7.io') {
                apiKey = process.env.WINNER_OS_CODE_IO
              } else if (window.location.hostname === 'winner7.org') {
                apiKey = process.env.WINNER_OS_CODE_ORG
              } else if (window.location.hostname === 'matrixexch.com') {
                apiKey = process.env.MATRIX_OS_CODE_COM
              } else if (window.location.hostname === 'matrixexch.club') {
                apiKey = process.env.MATRIX_OS_CODE_CLUB
              }
              window.OneSignal = window.OneSignal || []
              OneSignal.push(function () {
                OneSignal.init({
                  appId: apiKey
                })
              })
            }
            // One Signal End Here
          }
        } catch (error) {}
      }
      this.$store.dispatch('user/getDeploymentVersion')
    }

    // async getMaintenanceStatus() {
    //   await this.$store.dispatch('maintenance/getMaintenanceStatus')
    //   if (this.maintenanceStatus && this.maintenanceStatus.value) {
    //     this.$router.push('/maintenance')
    //   }
    // }
    // async oneSignalKey() {
    //   try {
    //     await this.$store.dispatch('oneSignalKey/getapiKey')
    //     if (this.apiKey) {
    //       window.OneSignal = window.OneSignal || []
    //       const OneSignal = window.OneSignal
    //       OneSignal.push(function () {
    //         OneSignal.init({
    //           appId: this.apiKey
    //           // appId: '89da04bb-6c9a-4adf-a601-315f5e033d2a',
    //           // allowLocalhostAsSecureOrigin: true
    //         })
    //       })
    //     }
    //   } catch (error) {}
    // }
  }
}
</script>
<style lang="scss" scoped></style>
