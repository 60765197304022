var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.loaded)?_c('div',[_c(_vm.playCacheClearModal,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('div',[_c(_vm.shareModal,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('div',[_c(_vm.notificationMarquee,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.loaded && _vm.$breakpoint.smAndDown)?_c('div',[_c(_vm.megaMenuDrawer,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.loaded && _vm.$breakpoint.smAndDown)?_c('div',[_c(_vm.mobileDrawer,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('div',[_c(_vm.networkBar,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.loaded && _vm.$auth.loggedIn)?_c('div',[_c(_vm.mainDrawer,{tag:"component"})],1):_vm._e(),_vm._v(" "),_c('div',[_c('div',{class:{
        'casino-wac':
          _vm.$route.name === 'casino-casino-list' ||
          _vm.$route.name === 'casino-games'
      }}),_vm._v(" "),(_vm.loaded)?_c('div',[_c(_vm.headerBar,{tag:"component"})],1):_vm._e(),_vm._v(" "),(_vm.loaded)?_c('v-main',{staticClass:"main-section",class:{
        'mt-15': _vm.$breakpoint.smAndDown,
        'pb-15':
          _vm.$breakpoint.smAndDown &&
          _vm.isBottomDrawerOpen &&
          (_vm.hasBottomBar === undefined ||
            _vm.hasBottomBar == true ||
            _vm.hasBottomBar == 'true'),
        'mb-12':
          this.$route.name !== 'live-casino-system-pageNo' &&
          (_vm.hasBottomBar === undefined ||
            _vm.hasBottomBar == true ||
            _vm.hasBottomBar == 'true'),
        'mb-2':
          this.$route.name !== 'live-casino-system-pageNo' &&
          !(
            _vm.hasBottomBar === undefined ||
            _vm.hasBottomBar == true ||
            _vm.hasBottomBar == 'true'
          )
      }},[_c(_vm.snackbar,{tag:"component"}),_vm._v(" "),_c('v-lazy',[_c('nuxt')],1)],1):_vm._e(),_vm._v(" "),(
        _vm.loaded &&
        _vm.$breakpoint.smAndDown &&
        (_vm.hasBottomBar === undefined ||
          _vm.hasBottomBar == true ||
          _vm.hasBottomBar == 'true')
      )?_c('div',[_c(_vm.navBar,{tag:"component",staticClass:"premium-topbar"})],1):_vm._e()],1),_vm._v(" "),(
      _vm.loaded &&
      (_vm.$route.name === 'index' ||
        _vm.$route.name === 'favorites' ||
        _vm.$route.name === 'inplay' ||
        _vm.$route.name === 'sportsbook-category' ||
        _vm.$route.name === 'sportsbook' ||
        _vm.$route.name === 'sportsbook-category-subCategory-id' ||
        _vm.$route.name === 'sportsbook-category-subCategory-id-betradar' ||
        _vm.$route.name === 'sportsbook-category-id')
    )?_c('div',{staticClass:"oneclick"},[_c(_vm.oneclickBet,{tag:"component"})],1):_vm._e(),_vm._v(" "),(
      _vm.loaded &&
      _vm.$auth.loggedIn &&
      _vm.$breakpoint.smAndDown &&
      _vm.$route.name !== 'premium-sportsbook' &&
      _vm.$route.name !== 'live-cards' &&
      _vm.$route.name !== 'casino-casino-list' &&
      _vm.$route.name !== 'casino-games'
    )?_c('div',[_c(_vm.favoritesMarquee,{tag:"component",attrs:{"id":"fav-marque"}})],1):_vm._e(),_vm._v(" "),(_vm.loaded && _vm.$auth.user && _vm.$auth.user._id)?_c('div',{staticClass:"d-none"},[_c(_vm.ipQuality,{tag:"component"})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }