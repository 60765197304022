const middleware = {}

middleware['maintenanceCheck'] = require('../middleware/maintenanceCheck.js')
middleware['maintenanceCheck'] = middleware['maintenanceCheck'].default || middleware['maintenanceCheck']

middleware['onlylogin'] = require('../middleware/onlylogin.js')
middleware['onlylogin'] = middleware['onlylogin'].default || middleware['onlylogin']

middleware['validateCasino'] = require('../middleware/validateCasino.js')
middleware['validateCasino'] = middleware['validateCasino'].default || middleware['validateCasino']

middleware['validateGame'] = require('../middleware/validateGame.js')
middleware['validateGame'] = middleware['validateGame'].default || middleware['validateGame']

middleware['validateUser'] = require('../middleware/validateUser.js')
middleware['validateUser'] = middleware['validateUser'].default || middleware['validateUser']

export default middleware
