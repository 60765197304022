export const state = () => ({
  iframeUrl: undefined,
  loading: false,
  userId: '',
  operatorId: '',
  liveGamesIframeUrl: 'https://livegame.marutix.com',
  error: {}
})

export const mutations = {
  set_data(state, data) {
    state.userId = data._id
    const accessToken = this.$auth.getToken('customLocal')
    state.operatorId = process.env.GAMES_OPERATOR_ID
    let stakesValues = '10,20,50,100,500,1000'
    let oneClickStakeValues = '10,20,50'
    if (this.$auth.user.currencyType === 'INR') {
      stakesValues = '1000,2000,5000,10000,50000,100000'
      oneClickStakeValues = '1000,2000,5000'
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      state.liveGamesIframeUrl = process.env.GAMES_IFRAME_D_URL
    } else {
      state.liveGamesIframeUrl = process.env.GAMES_IFRAME_D_URL
    }
    state.iframeUrl =
      state.liveGamesIframeUrl +
      '/#/fs?token=' +
      accessToken +
      '-' +
      state.userId +
      '&operatorId=' +
      state.operatorId +
      '&language=en' +
      '&stakes=' +
      stakesValues +
      '&oneclickstakes=' +
      oneClickStakeValues
  },

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true
    } else {
      return false
    }
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  async getLiveGamesUrl({ commit }, payload) {
    const userId = this.$auth.user._id
    const url = process.env.APP_URL
    const userName = this.$auth.user.username
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.GAMES_CUSTOM_URL,
        url: 'SportsBook/save',
        data: {
          ...payload,
          userId,
          url,
          userName
        }
      })
      if (response.data) {
        // console.log(response.data)
        commit('set_data', response.data)
      }

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
