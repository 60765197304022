export const state = () => ({
  data: {},
  loading: false,
  error: {},
  userLoginVerify: {},
  vpnData: {},
  appversion: {}
})

export const mutations = {
  set_data(state, data) {
    state.data = data
  },
  set_user_verify_data(state, data) {
    state.userLoginVerify = data
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_vpn_data(state, value) {
    state.vpnData = value
  },
  set_appversion(state, value) {
    state.appversion = value
  }
}

export const actions = {
  async get({ commit }) {
    const _accessToken = this.$auth.getToken('customLocal')
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'member/getOneForUI',
        data: { _accessToken }
      })
      if (response.data.value) {
        const user = response.data.data
        if (!user.accessToken.includes(_accessToken)) {
          this.dispatch('user/logout')
          window.location.reload(true)
        }
        // console.log(user)
        localStorage.setItem('userData', user)
        this.$auth.setUser(user)
        commit('set_data', user)
      }

      commit('set_loading', false)
    } catch (error) {
      // this.dispatch('user/logout')
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async logout({ commit }) {
    const accessToken = this.$auth.getToken('customLocal')
    try {
      await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'member/logout',
        data: { accessToken }
      })
      this.$auth.$storage.removeUniversal('timezone')
      this.$auth.$storage.removeUniversal('userConfig')
      this.$auth.$storage.removeUniversal('kycStatus')
      this.$auth.reset()
      localStorage.clear()
      window.location.reload()
    } catch (error) {
      commit('set_error', error)
      this.$auth.reset()
      localStorage.clear()
      this.$auth.$storage.removeUniversal('timezone')
      this.$auth.$storage.removeUniversal('userConfig')
      this.$auth.$storage.removeUniversal('kycStatus')
      window.location.reload()
    }
  },
  async checkLogin({ commit }) {
    const _accessToken = this.$auth.getToken('customLocal')
    try {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'Member/userLoginCheck',
        data: { _accessToken }
      })
      if (response && response.data && response.data.data) {
        commit('set_user_verify_data', response.data)
      }
      // console.log(response)
    } catch (err) {
      console.log(err)
    }
  },
  async getDeploymentVersion({ commit }) {
    const level = 'Frontend'
    // const _accessToken = this.$auth.getToken('customLocal')
    try {
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'Configurations/getAppVersionForCacheCheck',
        data: { level }
      })
      if (
        res &&
        res.data.value &&
        res.data &&
        res.data.data &&
        res.data.data.appVersion
      ) {
        commit('set_appversion', res.data.data)
      } else {
        commit('set_appversion', {})
        return '9999.9999.9999.9999'
      }
    } catch (err) {
      commit('set_error', err)
      commit('set_appversion', {})
      // if (
      //   err &&
      //   typeof err.toString() === 'string' &&
      //   err.toString().toLowerCase() === 'error: network error'
      // ) {
      //   console.log('In your Region Our Service Not Allowed', err.toString())
      // }
      return '9999.9999.9999.9999'
    }
  },
  async getSecretKey({ commit }, data) {
    try {
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'member/getSecreteKey',
        data: {
          name: data.siteName,
          userName: data.userName,
          _accessToken: data.accessToken
        }
      })
      if (res && res.data && res.data.data && res.data.value) {
        return res
      } else if (res && res.data && res.data.error) {
        console.log('err', res.data.error)
      }
    } catch (err) {
      commit('set_error', err)
    }
  },
  async verifySecretKey({ commit }, data) {
    try {
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'member/verifySecreteKey',
        data: {
          _accessToken: data.accessToken,
          token: data.token
        }
      })
      if (res) {
        return res
      } else if (res?.response) {
        console.log('err', res.response)
      } else if (res && res.data && res.data.error) {
        console.log('err', res.data.error)
      }
    } catch (err) {
      // console.log('err', err.response.data.value)
      commit('set_error', err?.response?.data?.error?.msg)
      commit('set_error', err)
    }
  },
  async change2fa({ commit }, data) {
    try {
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'member/change2faAuthFlag',
        data: {
          _accessToken: data.accessToken,
          username: data.userName,
          is2faEnabled: data.is2faEnabled,
          token: data.token
        }
      })
      if (res) {
        return res
      } else if (res && res.data && res.data.error) {
        console.log('err', res.data.error)
      }
    } catch (err) {
      commit('set_error', err)
    }
  },
  async checkvpn({ commit }) {
    try {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.SPORTSBOOK_API_URL,
        url: '/ipquality/vpnCheck',
        data: {}
      })
      commit('set_vpn_data', response)
      // console.log(response)
    } catch (err) {
      console.log(err)
    }
  }
}
