export const state = () => ({
  adminARUrl: undefined,
  loading: false,
  error: {},
  casinoGames: [],
  casinoIframeURL: ''
})

export const mutations = {
  // casino sub-games api
  set_game(state, data) {
    state.casinoGames = data
  },

  // casino sub-games iframe Url api
  set_game_url(state, data) {
    state.casinoIframeURL = data.launch_request_url
  },

  // live casino iframe apis
  set_data(state, data) {
    if (data && data.entry) {
      state.adminARUrl = process.env.AR1_URL + data.entry
    }
  },

  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  }
}

export const actions = {
  // don't remove its ESof Prologic
  // casino sub-games api
  async getGames({ commit }, payload) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PROLOGIC_URL,
        url: 'getGames',
        data: { payload, _accessToken: this.$auth.getToken('customLocal') }
      })

      commit('set_game', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // casino sub-games iframe Url api
  async getCasinoURL({ commit }, payload) {
    const userId = this.$auth.user._id
    const siteId = process.env.PROLOGIC_SITE_ID
    const userName = this.$auth.user.username
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.PROLOGIC_URL,
        url: 'Members/saveMember',
        data: {
          ...payload,
          userId,
          siteId,
          userName
        }
      })
      commit('set_game_url', response.data)

      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },

  // live casino iframe api
  async getLiveCasinoUrl({ commit }) {
    commit('set_data', '')
    // let accessToken, userId, username
    if (this.$auth.loggedIn) {
      // accessToken = this.$auth.getToken('customLocal')
      // userId = this.$auth.user._id
      // username = this.$auth.user.username
    }

    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.AR_API_URL,
        url: 'Sessions/createLoginSidNew',
        data: {
          // nickname: username + '_' + userId,
          game: 'roulette',
          // userId,
          // accessToken,
          mobile: false,
          currencyType: 'INR'
        }
      })
      commit('set_data', response.data)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
