export const state = () => ({
  gameConfigData: {},
  loading: false,
  error: {},
  isBottomDrawerOpen: false,
  notificationMarquee: true
})

export const mutations = {
  setgameConfigData(state, data) {
    state.gameConfigData.onlyDesktopNote = data
  },
  set_data(state, data) {
    state.gameConfigData = data.results[0]
    // console.log(state.gameConfigData)
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_isBottomDrawerOpen(state, data) {
    state.isBottomDrawerOpen = data
  },
  set_notificationMarquee(state, data) {
    state.notificationMarquee = data
  }
}

export const actions = {
  async getGameConfig({ commit }) {
    try {
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.CONFIG_V2_API_URL,
        url: 'GameConfig/search',
        data: {}
      })
      if (response.data.value) {
        commit('set_data', response.data.data)
      }
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}

export const getters = {
  gameConfigData: (state) => state.gameConfigData
}
