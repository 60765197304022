export const state = () => ({
  data: [],
  gameCategory: [],
  groupGames: [],
  getGames: [],
  onlyGames: [],
  getIframe: {},
  loading: false,
  error: {}
})

export const mutations = {
  set_data(state, data) {
    state.data = data
  },
  set_gameCategory(state, outputObject) {
    state.gameCategory = outputObject
  },
  set_groupGames(state, outputObject) {
    state.groupGames = outputObject
  },
  set_getGames(state, outputObject) {
    state.getGames = outputObject
  },
  set_onlyGames(state, outputObject) {
    state.onlyGames = outputObject
  },
  set_error(state, data) {
    state.error = data
  },
  set_IframeData(state, data) {
    state.getIframe = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  clear_group(state, propertyName) {
    if (state[propertyName] !== undefined) {
      state[propertyName] = []
    }
  }
}

export const actions = {
  async getProviders({ commit }) {
    try {
      commit('set_loading', true)
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.FUNDIST_URL,
        url: 'provider/getProviders',
        data: {}
      })

      const outputObject = res.data.data.outputObject.data
      commit('set_data', outputObject)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getGameCategory({ commit }, data) {
    try {
      const payload = {
        provider: data || ''
      }
      if (this.$auth.loggedIn) {
        payload.fundistCurrencyType = this.$auth.user.fundistCurrencyType
      }
      commit('set_loading', true)
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.FUNDIST_URL,
        url: 'category/getCategory',
        data: payload
      })
      const outputObject = res.data.data.outputObject.data
      commit('set_gameCategory', outputObject)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getGroupGames({ commit }, data) {
    try {
      const payload = {
        merchantName: data
      }
      if (this.$auth.loggedIn) {
        payload.fundistCurrencyType = this.$auth.user.fundistCurrencyType
      }
      commit('set_loading', true)
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.FUNDIST_URL,
        url: 'game/getGroupGames',
        data: payload
      })

      const outputObject = res.data.data.outputObject
      commit('set_groupGames', outputObject)
      commit('clear_group', 'getGames')
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getGames({ commit }, payload) {
    try {
      if (this.$auth.loggedIn) {
        payload.fundistCurrencyType = this.$auth.user.fundistCurrencyType
      }
      commit('set_loading', true)
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.FUNDIST_URL,
        url: 'game/getGames',
        data: payload
      })
      const outputObject = res.data.data.outputObject

      commit('set_getGames', outputObject)
      commit('clear_group', 'groupGames')
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getOnlyGames({ commit }, payload) {
    try {
      const reqData = {
        gameName: 'top'
      }
      if (this.$auth.loggedIn) {
        reqData.fundistCurrencyType = this.$auth.user.fundistCurrencyType
      }
      commit('set_loading', true)
      const res = await this.$axios({
        method: 'post',
        baseURL: process.env.FUNDIST_URL,
        url: 'game/getGames',
        data: reqData
      })
      const outputObject = res.data.data.outputObject

      commit('set_onlyGames', outputObject)
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
    }
  },
  async getIframe({ commit }, payload) {
    try {
      if (this.$auth.loggedIn) {
        commit('set_loading', true)
        const response = await this.$axios({
          method: 'post',
          baseURL: process.env.FUNDIST_URL,
          url: 'iFrame/createIFrame',
          data: payload
        })
        if (response?.data?.data?.outputObject) {
          commit('set_IframeData', response.data.data?.outputObject)
        }
        commit('set_loading', false)
      }
    } catch (error) {
      commit('set_error', error)
    }
  }
}
export const getters = {}
