export const state = () => ({
  model: false
})

export const mutations = {
  open(state, data) {
    state.model = true
  },
  close(state, data) {
    state.model = false
  },
  set(state, data) {
    state.model = data
  }
}

export const getters = {
  model: (state) => state.model
}
