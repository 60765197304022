import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ebe6a39 = () => interopDefault(import('../pages/aml-policies.vue' /* webpackChunkName: "pages/aml-policies" */))
const _3931f204 = () => interopDefault(import('../pages/bets.vue' /* webpackChunkName: "pages/bets" */))
const _2e1f9f98 = () => interopDefault(import('../pages/casino/index.vue' /* webpackChunkName: "pages/casino/index" */))
const _38e2119c = () => interopDefault(import('../pages/casino-slots/index.vue' /* webpackChunkName: "pages/casino-slots/index" */))
const _4f3260cc = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _13454834 = () => interopDefault(import('../pages/change-password-kyc.vue' /* webpackChunkName: "pages/change-password-kyc" */))
const _8c03ac32 = () => interopDefault(import('../pages/changePassword.vue' /* webpackChunkName: "pages/changePassword" */))
const _16afeeea = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _74e45790 = () => interopDefault(import('../pages/finsa/index.vue' /* webpackChunkName: "pages/finsa/index" */))
const _53538439 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _2efb2970 = () => interopDefault(import('../pages/google-auth.vue' /* webpackChunkName: "pages/google-auth" */))
const _61273b56 = () => interopDefault(import('../pages/inplay.vue' /* webpackChunkName: "pages/inplay" */))
const _636c58da = () => interopDefault(import('../pages/line-market-rules.vue' /* webpackChunkName: "pages/line-market-rules" */))
const _d872bd7e = () => interopDefault(import('../pages/live-cards/index.vue' /* webpackChunkName: "pages/live-cards/index" */))
const _01a0a2ad = () => interopDefault(import('../pages/live-casino/index.vue' /* webpackChunkName: "pages/live-casino/index" */))
const _5a56cf1f = () => interopDefault(import('../pages/live-games/index.vue' /* webpackChunkName: "pages/live-games/index" */))
const _cf5a0140 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _497284cb = () => interopDefault(import('../pages/login_2FA.vue' /* webpackChunkName: "pages/login_2FA" */))
const _4bd57c47 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _2a3de492 = () => interopDefault(import('../pages/my-markets.vue' /* webpackChunkName: "pages/my-markets" */))
const _71d8a962 = () => interopDefault(import('../pages/news.vue' /* webpackChunkName: "pages/news" */))
const _13830a35 = () => interopDefault(import('../pages/premium-bookmaker-fancy.vue' /* webpackChunkName: "pages/premium-bookmaker-fancy" */))
const _f8abbf4e = () => interopDefault(import('../pages/premium-cricket-rules.vue' /* webpackChunkName: "pages/premium-cricket-rules" */))
const _59e8e9c6 = () => interopDefault(import('../pages/premium-sportsbook/index.vue' /* webpackChunkName: "pages/premium-sportsbook/index" */))
const _c217a2f8 = () => interopDefault(import('../pages/premium-sportsbook-rules.vue' /* webpackChunkName: "pages/premium-sportsbook-rules" */))
const _03991043 = () => interopDefault(import('../pages/profit-loss/index.vue' /* webpackChunkName: "pages/profit-loss/index" */))
const _0f3eced6 = () => interopDefault(import('../pages/profit-loss-new/index.vue' /* webpackChunkName: "pages/profit-loss-new/index" */))
const _5459fb96 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _34479e67 = () => interopDefault(import('../pages/rules-regulations.vue' /* webpackChunkName: "pages/rules-regulations" */))
const _fa3ed64e = () => interopDefault(import('../pages/rummy-poker/index.vue' /* webpackChunkName: "pages/rummy-poker/index" */))
const _f7a93ac2 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _327ba967 = () => interopDefault(import('../pages/sportsbook/index.vue' /* webpackChunkName: "pages/sportsbook/index" */))
const _960ba552 = () => interopDefault(import('../pages/time-settings.vue' /* webpackChunkName: "pages/time-settings" */))
const _1ec423ba = () => interopDefault(import('../pages/transfer-statements.vue' /* webpackChunkName: "pages/transfer-statements" */))
const _2a2900e6 = () => interopDefault(import('../pages/transfer-statements-new.vue' /* webpackChunkName: "pages/transfer-statements-new" */))
const _2ee6a5d0 = () => interopDefault(import('../pages/wac/index.vue' /* webpackChunkName: "pages/wac/index" */))
const _17f09c4a = () => interopDefault(import('../pages/casino/casino-list/index.vue' /* webpackChunkName: "pages/casino/casino-list/index" */))
const _55c9aaa3 = () => interopDefault(import('../pages/casino/games.vue' /* webpackChunkName: "pages/casino/games" */))
const _49e93e5b = () => interopDefault(import('../pages/casino/live-casino/index.vue' /* webpackChunkName: "pages/casino/live-casino/index" */))
const _45a2436b = () => interopDefault(import('../pages/live-casino-old/live-casino.vue' /* webpackChunkName: "pages/live-casino-old/live-casino" */))
const _70ee1b7d = () => interopDefault(import('../pages/profit-loss-new/detail.vue' /* webpackChunkName: "pages/profit-loss-new/detail" */))
const _07dc07b0 = () => interopDefault(import('../pages/profit-loss/detail.vue' /* webpackChunkName: "pages/profit-loss/detail" */))
const _ec0d9552 = () => interopDefault(import('../pages/casino/casino-list/index-copy.vue' /* webpackChunkName: "pages/casino/casino-list/index-copy" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9b12038c = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/index" */))
const _065b27b6 = () => interopDefault(import('../pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index.vue' /* webpackChunkName: "pages/casino/casino-list/_provider/_gameType/_subGameType/_id/index" */))
const _79bfcb04 = () => interopDefault(import('../pages/casino-slots/_id.vue' /* webpackChunkName: "pages/casino-slots/_id" */))
const _7944002e = () => interopDefault(import('../pages/live-cards/_id.vue' /* webpackChunkName: "pages/live-cards/_id" */))
const _595959c8 = () => interopDefault(import('../pages/login/_id.vue' /* webpackChunkName: "pages/login/_id" */))
const _e2101816 = () => interopDefault(import('../pages/sportsbook/_category/index.vue' /* webpackChunkName: "pages/sportsbook/_category/index" */))
const _1083dc38 = () => interopDefault(import('../pages/wac/_id.vue' /* webpackChunkName: "pages/wac/_id" */))
const _25c48a3d = () => interopDefault(import('../pages/live-casino-old/_category/_id.vue' /* webpackChunkName: "pages/live-casino-old/_category/_id" */))
const _21307028 = () => interopDefault(import('../pages/live-casino/_system/_pageNo/index.vue' /* webpackChunkName: "pages/live-casino/_system/_pageNo/index" */))
const _0461f24f = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/index.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/index" */))
const _0ccf5348 = () => interopDefault(import('../pages/sportsbook/_category/_subCategory/_id/_betradar.vue' /* webpackChunkName: "pages/sportsbook/_category/_subCategory/_id/_betradar" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aml-policies",
    component: _1ebe6a39,
    name: "aml-policies"
  }, {
    path: "/bets",
    component: _3931f204,
    name: "bets"
  }, {
    path: "/casino",
    component: _2e1f9f98,
    name: "casino"
  }, {
    path: "/casino-slots",
    component: _38e2119c,
    name: "casino-slots"
  }, {
    path: "/change-password",
    component: _4f3260cc,
    name: "change-password"
  }, {
    path: "/change-password-kyc",
    component: _13454834,
    name: "change-password-kyc"
  }, {
    path: "/changePassword",
    component: _8c03ac32,
    name: "changePassword"
  }, {
    path: "/favorites",
    component: _16afeeea,
    name: "favorites"
  }, {
    path: "/finsa",
    component: _74e45790,
    name: "finsa"
  }, {
    path: "/forgot-password",
    component: _53538439,
    name: "forgot-password"
  }, {
    path: "/google-auth",
    component: _2efb2970,
    name: "google-auth"
  }, {
    path: "/inplay",
    component: _61273b56,
    name: "inplay"
  }, {
    path: "/line-market-rules",
    component: _636c58da,
    name: "line-market-rules"
  }, {
    path: "/live-cards",
    component: _d872bd7e,
    name: "live-cards"
  }, {
    path: "/live-casino",
    component: _01a0a2ad,
    name: "live-casino"
  }, {
    path: "/live-games",
    component: _5a56cf1f,
    name: "live-games"
  }, {
    path: "/login",
    component: _cf5a0140,
    name: "login"
  }, {
    path: "/login_2FA",
    component: _497284cb,
    name: "login_2FA"
  }, {
    path: "/maintenance",
    component: _4bd57c47,
    name: "maintenance"
  }, {
    path: "/my-markets",
    component: _2a3de492,
    name: "my-markets"
  }, {
    path: "/news",
    component: _71d8a962,
    name: "news"
  }, {
    path: "/premium-bookmaker-fancy",
    component: _13830a35,
    name: "premium-bookmaker-fancy"
  }, {
    path: "/premium-cricket-rules",
    component: _f8abbf4e,
    name: "premium-cricket-rules"
  }, {
    path: "/premium-sportsbook",
    component: _59e8e9c6,
    name: "premium-sportsbook"
  }, {
    path: "/premium-sportsbook-rules",
    component: _c217a2f8,
    name: "premium-sportsbook-rules"
  }, {
    path: "/profit-loss",
    component: _03991043,
    name: "profit-loss"
  }, {
    path: "/profit-loss-new",
    component: _0f3eced6,
    name: "profit-loss-new"
  }, {
    path: "/registration",
    component: _5459fb96,
    name: "registration"
  }, {
    path: "/rules-regulations",
    component: _34479e67,
    name: "rules-regulations"
  }, {
    path: "/rummy-poker",
    component: _fa3ed64e,
    name: "rummy-poker"
  }, {
    path: "/settings",
    component: _f7a93ac2,
    name: "settings"
  }, {
    path: "/sportsbook",
    component: _327ba967,
    name: "sportsbook"
  }, {
    path: "/time-settings",
    component: _960ba552,
    name: "time-settings"
  }, {
    path: "/transfer-statements",
    component: _1ec423ba,
    name: "transfer-statements"
  }, {
    path: "/transfer-statements-new",
    component: _2a2900e6,
    name: "transfer-statements-new"
  }, {
    path: "/wac",
    component: _2ee6a5d0,
    name: "wac"
  }, {
    path: "/casino/casino-list",
    component: _17f09c4a,
    name: "casino-casino-list"
  }, {
    path: "/casino/games",
    component: _55c9aaa3,
    name: "casino-games"
  }, {
    path: "/casino/live-casino",
    component: _49e93e5b,
    name: "casino-live-casino"
  }, {
    path: "/live-casino-old/live-casino",
    component: _45a2436b,
    name: "live-casino-old-live-casino"
  }, {
    path: "/profit-loss-new/detail",
    component: _70ee1b7d,
    name: "profit-loss-new-detail"
  }, {
    path: "/profit-loss/detail",
    component: _07dc07b0,
    name: "profit-loss-detail"
  }, {
    path: "/casino/casino-list/index-copy",
    component: _ec0d9552,
    name: "casino-casino-list-index-copy"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType",
    component: _9b12038c,
    name: "casino-casino-list-provider-gameType-subGameType"
  }, {
    path: "/casino/casino-list/:provider?/:gameType/:subGameType?/:id",
    component: _065b27b6,
    name: "casino-casino-list-provider-gameType-subGameType-id"
  }, {
    path: "/casino-slots/:id",
    component: _79bfcb04,
    name: "casino-slots-id"
  }, {
    path: "/live-cards/:id?",
    component: _7944002e,
    name: "live-cards-id"
  }, {
    path: "/login/:id",
    component: _595959c8,
    name: "login-id"
  }, {
    path: "/sportsbook/:category",
    component: _e2101816,
    name: "sportsbook-category"
  }, {
    path: "/wac/:id",
    component: _1083dc38,
    name: "wac-id"
  }, {
    path: "/live-casino-old/:category?/:id",
    component: _25c48a3d,
    name: "live-casino-old-category-id"
  }, {
    path: "/live-casino/:system?/:pageNo",
    component: _21307028,
    name: "live-casino-system-pageNo"
  }, {
    path: "/sportsbook/:category/:subCategory",
    component: _0461f24f,
    name: "sportsbook-category-subCategory"
  }, {
    path: "/sportsbook/:category/:subCategory/:id/:betradar?",
    component: _0ccf5348,
    name: "sportsbook-category-subCategory-id-betradar"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
