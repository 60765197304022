export const state = () => ({
  data: {},
  items: [],
  loading: false,
  viewMore: true,
  // totalItems: 0,
  lastTransactionId: '',
  totalItems: 0,
  error: {},
  userId: ''
})

export const mutations = {
  set_data(state, data) {
    state.userId = this.$auth.user._id
    state.items = data.data.results
    const itemsLength = data.data.results.length - 1
    state.lastTransactionId = data.data.results[itemsLength]._id
  },
  set_dataNew(state, data) {
    state.userId = this.$auth.user._id
    state.items = data
    state.totalItems = data.length
  },
  set_error(state, data) {
    state.error = data
  },
  set_loading(state, data) {
    state.loading = data
  },
  // get subGame API called integrate
  set_view_more_data(state, data) {
    state.viewMore = data
  }
}

export const actions = {
  async get({ commit }, payload) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'transaction/searchPlayerTransactionDataBigQuery',
        data: {
          _id: this.$auth.user._id,
          lastId: payload.lastTransactionId
        }
      })
      commit('set_view_more_data', true)
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.results &&
        response.data.data.results.length < 20
      ) {
        commit('set_view_more_data', false)
      }
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.results &&
        payload.lastTransactionId
      ) {
        const transactionData = {
          data: {
            results: [...payload.results, ...response.data.data.results]
          },
          value: response.data.value
        }
        commit('set_data', transactionData)
      } else if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.results &&
        response.data.data.results.length > 0
      ) {
        commit('set_data', response.data)
      } else {
        commit('set_data', response.data)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  },
  async getNew({ commit }, data) {
    try {
      commit('set_loading', true)
      const response = await this.$axios({
        method: 'post',
        baseURL: process.env.USER_V2_API_URL,
        url: 'transaction/searchPlayerTransactionDataBigQuery',
        data: {
          _id: data._id,
          createdAt: data.createdAt,
          lastId: data.lastId
        }
      })
      commit('set_view_more_data', true)
      commit('set_dataNew', response.data.data)
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length < 20
      ) {
        commit('set_view_more_data', false)
      }
      commit('set_loading', false)
    } catch (error) {
      commit('set_error', error)
      commit('set_loading', false)
    }
  }
}
