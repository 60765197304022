import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_7e9adef5 from './schemes/customLocal.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/","home":"/","callback":false},"vuex":{"namespace":"auth"},"cookie":false,"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local","router":{"middleware":["auth"],"prefetchLinks":false}}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/auth/login","method":"post","propertyName":"token"},"logout":{"url":"/api/auth/logout","method":"post"},"user":{"url":"/api/auth/user","method":"get","propertyName":"user"}},"_name":"local"}))

  // customLocal
  $auth.registerStrategy('customLocal', new scheme_7e9adef5($auth, {"endpoints":{"login":{"url":"member/playerLogin","method":"post","propertyName":"data.accessToken"},"logout":{"url":"member/logout","method":"post"},"user":{"url":"member/getOneForUI","method":"post","propertyName":"data"},"verifyjwt":{"url":"member/verifyjwt","method":"post","propertyName":"data"},"deviceIdCheck":{"url":"member/getCurrentDeviceId","method":"post"},"verifyLogin2fa":{"url":"member/verifySecreteKeyForPlayerLogin","method":"post","propertyName":"data.accessToken"},"migrateLogin":{"url":"member/playerLoginMigrated","method":"post","propertyName":"data.accessToken"},"saveUserDeviceId":{"url":"/Memberdevice/saveDeviceId","method":"post"}},"globalToken":true,"autoFetchUser":true,"tokenRequired":true,"tokenName":"x-key-id","tokenType":"PlayExch User","token":"","_name":"customLocal"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
