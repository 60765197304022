// eslint-disable-next-line import/no-named-as-default
import io from 'socket.io-client'
const socketOptions = {
  reconnection: true,
  reconnectionDelay: 500,
  reconnectionDelayMax: 500,
  reconnectionAttempts: Infinity,
  forceNew: true,
  transports: ['websocket'],
  timeout: 10000
}

const commonSocket = io(process.env.MAIN_SOCKET_URL, socketOptions)
const rateSocket = io(process.env.RATE_SOCKET_URL, socketOptions)

export default { commonSocket, rateSocket }
