import Vue from 'vue'
Vue.mixin({
  methods: {
    getLogo(theme) {
      let logo = process.env.THEME_LOGO
      if (theme && !theme.isDark) {
        logo = process.env.THEME_LOGO_LIGHT
      } else {
        logo = process.env.THEME_LOGO
      }
      try {
        return require(`@/assets/logos/${logo}`)
      } catch (error) {
        return ''
      }
    }
  }
})

export default ({ app }) => {
  const iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  if (iOS)
    document.head.querySelector('meta[name="viewport"]').content =
      'width=device-width, initial-scale=1, maximum-scale=1'
  else
    document.head.querySelector('meta[name="viewport"]').content =
      'width=device-width, initial-scale=1'
}
