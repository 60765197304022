<template>
  <v-expand-transition>
    <v-system-bar
      v-if="gameConfigData && gameConfigData.onlyDesktopNote && show"
      class="marquee-section justify-center"
      color="errors"
      app
    >
      <marquee class="w-100" scrolldelay="150">
        <span>{{ gameConfigData.onlyDesktopNote }}</span>
      </marquee>
      <v-spacer></v-spacer>
      <v-icon @click="hideStrip()">mdi-close</v-icon>
    </v-system-bar>
  </v-expand-transition>
</template>
<script>
import { mapState } from 'vuex'
import socket from '@/plugins/socket.client'
export default {
  data() {
    return {
      show: true
    }
  },
  computed: {
    ...mapState({
      gameConfigData: (state) => state.notificationMarquee.gameConfigData
    })
  },
  created() {
    this.$store.dispatch('notificationMarquee/getGameConfig')
    socket.commonSocket.on('kingsConfig', (data) => {
      const noteData = data.onlyDesktopNote
      this.$store.commit('notificationMarquee/setgameConfigData', noteData)
    })
  },
  beforeDestroy() {
    socket.commonSocket.off('kingsConfig')
  },
  methods: {
    hideStrip() {
      this.show = !this.show
    }
  }
}
</script>
<style lang="scss">
.marquee-section {
  position: relative !important;
  .icon-section {
    position: absolute;
    top: 0;
    right: 0;
  }
  .icon-section :hover {
    color: red;
  }
}
</style>
