export const state = () => ({
  loading: false,
  error: {},
  success: {},
  data: {},
  modalStatus: false
})
export const mutations = {
  set_data(state, data) {
    state.data = data
    window.open(state.data.loginId)
  },
  set_success(state, data) {
    state.success = data
    this.commit('snackbar/open', {
      text: state.success,
      color: 'success'
    })
  },
  set_error(state, data) {
    state.error = data
    this.commit('snackbar/open', {
      text: state.error,
      color: 'error'
    })
  },
  set_loading(state, data) {
    state.loading = data
  },
  set_modalStatus(state, data) {
    state.modalStatus = data
  }
}
export const actions = {
  async login({ commit }) {
    commit('set_loading', true)
    try {
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.FINSA_URL,
        url: '/user/login',
        data: {
          _accessToken: this.$auth.getToken('customLocal')
        }
      })
      commit('set_loading', false)
      if (data && data.data && data.value) {
        commit('set_success', data.message)
        commit('set_data', data.data)
      }
    } catch (error) {
      commit('set_loading', false)
      commit('set_error', error.response.data.message)
    }
  },
  async transaction({ commit }, { amount, title }) {
    commit('set_loading', true)
    let setUrl
    if (title === 'Deposit') {
      setUrl = '/transaction/deposit'
    }
    if (title === 'Withdraw') {
      setUrl = '/transaction/withdrawl'
    }
    try {
      const { data } = await this.$axios({
        method: 'post',
        baseURL: process.env.FINSA_URL,
        url: setUrl,
        data: {
          _accessToken: this.$auth.getToken('customLocal'),
          amount
        }
      })
      commit('set_loading', false)
      if (data && data.data && data.value) {
        commit('set_success', data.message)
        commit('set_modalStatus', false)
      }
    } catch (error) {
      commit('set_loading', false)
      commit('set_error', error.response.data.message)
    }
  },
  closeModal({ commit }) {
    commit('set_modalStatus', false)
  },
  openModal({ commit }) {
    commit('set_modalStatus', true)
  }
}
