// vuetify.options.js
const colorsDark = {
  primary: process.env.THEME_PRIMARY || '#e15b27',
  info: process.env.THEME_INFO || '#e76f26',
  secondary: '#a5d9fe',
  accent: '#f8d0ce',
  background: process.env.THEME_DARK || '#121212',
  logocolor: process.env.THEME_LOGO_BG || '#121212'
}
const colorsLight = {
  primary: process.env.THEME_PRIMARY || '#e15b27',
  info: process.env.THEME_INFO || '#e76f26',
  secondary: '#a5d9fe',
  accent: '#f8d0ce',
  background: process.env.THEME_LIGHT || '#ffffff',
  logocolor: process.env.THEME_LOGO_BG || '#121212'
}
export default {
  theme: {
    options: {
      customProperties: true
    },
    dark:
      process.env.THEME_DARK_VALUE === undefined ||
      process.env.THEME_DARK_VALUE === true ||
      process.env.THEME_DARK_VALUE === 'true',
    themes: {
      dark: colorsDark,
      light: colorsLight
    }
  }
}
