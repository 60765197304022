// import axios from 'axios'
export default function ({ $axios, app, redirect, store }) {
  // axios.interceptors.request.use((req) => {
  //   console.log('request')
  // })
  // axios.interceptors.request.use(function (config) {
  //     console.log("req.config", config)
  // })
  $axios.interceptors.response.use(
    (config) => {
      //   console.log('config', config)
      // Do something before request is sent
      return config
    },
    function (error) {
      // if (error && error.message && error.message === 'Network Error') {
      //   redirect('/region-block')
      // } else
      //   console.log('error---', error.response)
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.errorMsg
      ) {
        store.commit('snackbar/open', {
          text: error.response.data.errorMsg,
          color: 'error'
        })
        setTimeout(() => {
          store.dispatch('user/logout')
          redirect('/login')
        }, 2000)
      } else if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        if (
          error.response.data &&
          error.response.data.error &&
          error.response.data.error.data
        ) {
          store.commit('snackbar/open', {
            text: error.response.data.error.data,
            color: 'error'
          })
        } else {
          store.commit('snackbar/open', {
            text: 'Dear club member, your session has been expired. Please login again!',
            color: 'error'
          })
        }
        setTimeout(() => {
          store.dispatch('user/logout')
          redirect('/login')
        }, 1000)
      } else if (
        error &&
        error.response &&
        error.response.status &&
        error.response.status === 429
      ) {
        store.commit('snackbar/open', {
          text:
            error.response.data && error.response.data.error
              ? error.response.data.error
              : 'You sent too many requests. Please wait a while then try again',
          color: 'error'
        })
      }
      // Do something with request error
      return Promise.reject(error)
    }
  )
}
